<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="headerClass">
    <template #header>
      <b-container fluid class="px-2">
        <b-row>
          <b-col cols="auto" class="p-0">
            <ComputerOnline :computer="computer"/>
            <UserConnected :computer="computer"/>
            <WinUser :computer="computer"/>
            <CustomerAssigned :computer="computer"/>
          </b-col>
          <b-col cols="2">
            <span v-if="!hostnameControl" @click="hostnameControl = true" class="badge-control clickable"
                  title="Funktionen öffnen">
              {{ computer.hostname }}
            </span>
            <span v-else style="margin-top: -2px">
              <ClipboardUtility :value="computer.hostname" title="Hostname kopieren"/>
              <CustomerAssign :computer="computer" @refresh="refresh"/>
              <b-badge :variant="variantDanger" @click="hostnameControl = false" class="p-1 px-2 b-r clickable" title="Schließen">
                <b-icon-x scale="1.8"/>
              </b-badge>
            </span>
          </b-col>
          <b-col cols="1" class="px-0">
            <Clipboard :value="computer.ipv4_address" title="IPv4-Adresse kopieren"/>
          </b-col>
          <b-col cols="1">
            <HardwareInformation :computer="computer"/>
          </b-col>
          <b-col cols="6">
            <b-icon-house-fill scale="1.2" variant="primary" class="computer-icon clickable" :animation="loadingCheckIn"
                               :id="'tt-check-in-' + computer.id" @click="checkIn"/>
            <b-tooltip :target="'tt-check-in-' + computer.id" triggers="hover">PC einchecken</b-tooltip>
            <b-icon-truck scale="1.2" :class="[checkOutDisabled ? '' : 'clickable', 'computer-icon', 'ml-3']"
                          :id="'tt-check-out-' + computer.id" :animation="loadingCheckOut"
                          :variant="checkOutDisabledVariant" v-on="{click: checkOutDisabled ? function(){} : checkOut}"/>
            <b-tooltip :target="'tt-check-out-' + computer.id" triggers="hover">PC auschecken</b-tooltip>
          </b-col>
          <b-col cols="auto" class="ml-auto p-0">
            <ComputerWarnings :computer="computer"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import Clipboard from "@/components/utility/Clipboard.vue";
import WinUser from "@/components/lists/computerList/computerRow/WinUser";
import UserConnected from "@/components/lists/computerList/computerRow/UserConnected";
import ComputerOnline from "@/components/lists/computerList/computerRow/ComputerOnline";
import ComputerWarnings from "@/components/lists/computerList/computerRow/ComputerWarnings";
import HardwareInformation from "@/components/lists/computerList/computerRow/HardwareInformation";
import CustomerAssigned from "@/components/lists/computerList/computerRow/CustomerAssigned.vue";
import {putRequest} from "@/modules/requests";
import ClipboardUtility from "@/components/utility/ClipboardUtility.vue";
import CustomerAssign from "@/components/utility/CustomerAssign.vue";

export default {
  name: "ShippingRow",
  props: ['computer'],
  components: {
    CustomerAssign,
    ClipboardUtility,
    CustomerAssigned,
    WinUser,
    Clipboard,
    UserConnected,
    ComputerOnline,
    ComputerWarnings,
    HardwareInformation
  },
  data() {
    return {
      hostnameControl: false,
      loadingCheckInVar: false,
      loadingCheckOutVar: false,
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    async checkIn() {
      this.loadingCheckInVar = true
      await putRequest('shippingList/checkin/' + this.computer.pc_uuid, null, this, 'Der PC wurde erfolgreich eingecheckt.', 'Der PC konnte nicht erfolgreich eingecheckt werden.')
          .finally(() => {
            this.loadingCheckInVar = false
          })
    },
    async checkOut() {
      this.loadingCheckOutVar = true
      await putRequest('shippingList/checkout/' + this.computer.pc_uuid, null, this, 'Der PC wurde erfolgreich ausgecheckt.', 'Der PC konnte nicht erfolgreich ausgecheckt werden.')
          .finally(() => {
            this.loadingCheckOutVar = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    headerClass() {
      let classes = ['card-row', 'rounded']
      if(this.computer.customer && this.computer.customer.inactive) {
        classes.push('card-row-danger')
      }
      if(this.computer.customer_rpc && this.computer.customer_rpc.inactive) {
        classes.push('card-row-danger')
      }
      if(this.computer.customer_assigned && this.computer.customer_assigned.inactive) {
        classes.push('card-row-danger')
      }
      return classes
    },
    checkOutDisabled() {
      return !this.computer.customer_assigned
    },
    checkOutDisabledVariant() {
      return this.computer.customer_assigned ? 'primary' : 'grey-650'
    },
    loadingCheckIn() {
      if(this.loadingCheckInVar) {
        return 'throb'
      }
      return ''
    },
    loadingCheckOut() {
      if(this.loadingCheckOutVar) {
        return 'throb'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/Color.scss";

.dark .card-row:hover {
  background-color: $grey-700 !important;
}

.light .card-row:hover {
  background-color: $grey-100 !important;
}
</style>
